import {useEffect, useState} from 'react'
import {isValidPhoneNumber} from 'react-phone-number-input'
import {useBi, useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'

import {getLogger} from '../../../../utils/getLogger'
import {useReservationConfirmationStorage} from '../../storage'
import {getReservationLocationById} from '../../../../utils/reservationLocation'
import {RequestStatus} from '../../../../utils/wrapRequest'
import {PHONE_NUMBER_INCORRECT, INVALID_REVISION} from '../../../../services/errors'

export const useHooks = (isOpen: boolean, onClose: () => void) => {
  const {
    reservation,
    reservationLocations,
    getReservation,
    cancelReservation: cancelReservationApi,
    resetCancelReservationStatus,
    cancelReservationStatus,
    cancelReservationError,
  } = useReservationConfirmationStorage()
  const {t} = useTranslation()
  const {isMobile, isPreview} = useEnvironment()
  const bi = useBi()
  const logger = getLogger(bi)

  const [phone, setPhone] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [phoneValid, setPhoneValid] = useState(true)

  const isCancelReservationLoading = cancelReservationStatus === RequestStatus.LOADING
  const isCancelReservationResolved = cancelReservationStatus === RequestStatus.RESOLVED
  const currentReservationLocation = getReservationLocationById(
    reservation?.details?.reservationLocationId ?? '',
    reservationLocations,
  )
  const phoneIncorrect =
    cancelReservationError?.details?.applicationError?.code === PHONE_NUMBER_INCORRECT
  const invalidRevision =
    cancelReservationError?.details?.applicationError?.code === INVALID_REVISION

  const cancelReservation = async () => {
    setSubmitted(true)

    if (!isValidPhoneNumber(phone)) {
      setPhoneValid(false)
      return
    }

    if (reservation) {
      await cancelReservationApi({
        reservationId: reservation.id ?? '',
        revision: reservation.revision ?? '',
        phone,
      })

      logger.reservationCanceled(isPreview, reservation)
    }
  }

  const handlePhoneChange = (value: string) => {
    setPhone(value)
    setPhoneValid(isValidPhoneNumber(value))
    resetCancelReservationStatus()
  }

  const phoneError = phoneIncorrect
    ? t(PHONE_ERRORS.notFound)
    : !phoneValid
    ? t(PHONE_ERRORS.invalid)
    : undefined

  const phoneErrorText = submitted ? phoneError : undefined

  useEffect(() => {
    if (isCancelReservationResolved) {
      onClose()
    }
  }, [isCancelReservationResolved, onClose])

  useEffect(() => {
    ;(async () => {
      if (invalidRevision) {
        await getReservation(reservation?.id ?? '')
        onClose()
      }
    })()
  }, [invalidRevision, reservation?.id, getReservation, onClose])

  useEffect(() => {
    // reset state when modal closes
    if (!isOpen) {
      setPhone('')
      setSubmitted(false)
      setPhoneValid(true)
      resetCancelReservationStatus()
    }
  }, [isOpen, resetCancelReservationStatus])

  return {
    t,
    isMobile,
    isCancelReservationLoading,
    phone,
    phoneErrorText,
    handlePhoneChange,
    cancelReservation,
    country: currentReservationLocation?.location?.address?.country ?? '',
  }
}

const PHONE_ERRORS = {
  notFound: 'uou-reservations.reservation-confirmation.modal-cancel-phone.error-no-found',
  invalid: 'uou-reservations.reservation-confirmation.modal-cancel-phone.error-invalid',
}
