import PhoneInputLib, {Country as CountryCodeType} from 'react-phone-number-input'
import React from 'react'
import {TextField, TextFieldTheme, Text} from 'wix-ui-tpa/cssVars'

import './phone.global.scss'
import {classes, st} from './styles.st.css'

interface PhoneInputProps {
  label: string
  wrapperClassName?: string
  labelClassName?: string
  className?: string
  textFieldTheme: TextFieldTheme
  value: string
  onChange: (value: string) => void
  defaultCountry: string
  dataHook?: string
  disabled?: boolean
  invalid?: boolean
}

export const PhoneInput: React.FC<PhoneInputProps> = ({
  label,
  textFieldTheme,
  wrapperClassName,
  labelClassName,
  className,
  onChange,
  value,
  defaultCountry,
  dataHook,
  disabled,
  invalid,
}) => {
  return (
    <div className={wrapperClassName}>
      <Text // @ts-expect-error
        for="tr-phone-input"
        className={labelClassName}
        tagName="label"
        data-hook="label"
      >
        {label}
      </Text>

      <div data-hook="tr-phone-input-wrapper">
        <PhoneInputLib
          flagUrl="https://static.parastorage.com/unpkg/country-flag-icons@1.5.5/flags/3x2/{XX}.svg"
          value={value}
          defaultCountry={defaultCountry as CountryCodeType}
          onChange={(val) => onChange(val ?? '')}
          inputComponent={PhoneInputComponent}
          numberInputProps={{
            textFieldTheme,
            className: st(className, invalid ? classes.phoneInputError : undefined),
            'data-hook': dataHook,
          }}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

interface PhoneInputComponentComponentProps {
  className: string
  textFieldTheme: TextFieldTheme
  'data-hook'?: string
}

const PhoneInputComponent = React.forwardRef<HTMLInputElement, PhoneInputComponentComponentProps>(
  (props, ref) => (
    <TextField
      {...props}
      // bad typings that not support ref passing
      // @ts-expect-error
      inputRef={ref}
      maxLength={20}
      name="phoneNumber"
      id="tr-phone-input"
      required
      theme={props.textFieldTheme}
    />
  ),
)
