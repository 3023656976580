import React from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {Button, Dialog, Text} from 'wix-ui-tpa/cssVars'

import {classes, st} from './styles.st.css'
import {useHooks} from './useHooks'

export const ErrorModal: React.FC = () => {
  const {t} = useTranslation()
  const {isModalOpen, onClose, onCtaButtonClick} = useHooks()

  return (
    <Dialog
      className={st(classes.root)}
      isOpen={isModalOpen}
      onClose={onClose}
      wiredToSiteColors={true}
    >
      <div className={classes.content}>
        <Text tagName="H3" className={classes.title}>
          {t('uou-reservations.reservation-confirmation.error-modal-title')}
        </Text>
        <Text className={classes.text}>
          {t('uou-reservations.reservation-confirmation.error-modal-text')}
        </Text>
        <Button onClick={onCtaButtonClick} className={classes.ctaButton}>
          {t('uou-reservations.reservation-confirmation.error-modal-cta')}
        </Button>
      </div>
    </Dialog>
  )
}
