import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export type TextsSettingsParams = {
  titleReservedText: SettingsParamType.String
  titleRequestedText: SettingsParamType.String
}

export const textsSettingsParams = createSettingsParams<TextsSettingsParams>({
  titleReservedText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservation-confirmation.title'),
  },
  titleRequestedText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservation-confirmation.requested-title'),
  },
})
