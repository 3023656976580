import {useState} from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'

import {useReservationConfirmationStorage} from '../storage'
import {getReservationLocationById} from '../../../utils/reservationLocation'
import {useGetLayoutSize} from '../../../components-shared/LayoutSizeProvider'

import {getCalendarHref, getMessageType} from './utils'

export const useHooks = () => {
  const {reservation, reservationLocations, goToNewReservation, regionalSettings} =
    useReservationConfirmationStorage()

  const {t} = useTranslation()
  const [isCancelDialogOpened, setIsCancelDialogOpened] = useState<boolean>(false)

  const currentReservationLocation = getReservationLocationById(
    reservation?.details?.reservationLocationId ?? '',
    reservationLocations,
  )

  const openCancelDialog = () => {
    setIsCancelDialogOpened(true)
  }

  const closeCancelDialog = () => {
    setIsCancelDialogOpened(false)
  }

  const {layoutSize} = useGetLayoutSize()

  return {
    t,
    layoutSize,
    isCancelDialogOpened,
    reservation,
    regionalSettings,
    goToNewReservation,
    openCancelDialog,
    closeCancelDialog,
    calendarHref: getCalendarHref(currentReservationLocation, reservation),
    messageType: getMessageType(reservation?.reservee ?? ''),
    formattedAddress: currentReservationLocation?.location?.address?.formattedAddress ?? '',
    locationName: currentReservationLocation?.location?.name ?? '',
    timeZone: currentReservationLocation?.location?.timeZone,
    phoneNumber: currentReservationLocation?.location?.phone,
  }
}
