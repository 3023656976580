import React, {FC} from 'react'
import {
  Button,
  ButtonPriority,
  Dialog,
  StatesButton,
  StatesButtonStates,
  Text,
  TextFieldTheme,
  TextTypography,
  ThreeDotsLoader,
} from 'wix-ui-tpa/cssVars'

import {PhoneInput} from '../../../../components-shared/PhoneInput'
import {FormError} from '../../../../components-shared/FormError'
import {reservationConfirmationDataHooks} from '../../Widget/constants'
import {classes as widgetClasses} from '../../Widget/Widget.st.css'

import {st, classes} from './styles.st.css'
import {useHooks} from './useHooks'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const CancelModal: FC<Props> = ({isOpen, onClose}) => {
  const {
    t,
    isMobile,
    phone,
    country,
    phoneErrorText,
    isCancelReservationLoading,
    cancelReservation,
    handlePhoneChange,
  } = useHooks(isOpen, onClose)

  return (
    <Dialog
      isOpen={isOpen}
      contentClassName={classes.cancelDialog}
      onClose={onClose}
      className={st(classes.root, {
        mobile: isMobile,
      })}
      wiredToSiteColors={true}
    >
      <div>
        <Text typography={TextTypography.largeTitle} className={widgetClasses.title} tagName="h2">
          {t('uou-reservations.reservation-confirmation.modal-cancel-title')}
        </Text>
        <div className={st(widgetClasses.bodyText, classes.cancelDialogTextWrapper)}>
          <Text typography={TextTypography.listText} tagName="p" className={widgetClasses.bodyText}>
            {t('uou-reservations.reservation-confirmation.modal-cancel-text')}
          </Text>
        </div>
        <div
          className={st(
            classes.cancelDialogPhoneWrapper,
            phoneErrorText ? classes.cancelDialogPhoneWrapperWithError : undefined,
          )}
        >
          <PhoneInput
            label={t('uou-reservations.reservation-confirmation.modal-cancel-phone.label')}
            value={phone}
            onChange={handlePhoneChange}
            textFieldTheme={TextFieldTheme.Box}
            wrapperClassName={classes.phoneWrapper}
            labelClassName={st(widgetClasses.bodyText, classes.phoneInputLabel)}
            disabled={isCancelReservationLoading}
            invalid={!!phoneErrorText}
            defaultCountry={country}
            dataHook={reservationConfirmationDataHooks.cancelReservationPhoneInput()}
          />
          {phoneErrorText && (
            <FormError
              text={phoneErrorText}
              className={st(widgetClasses.bodyText, classes.error)}
            />
          )}
        </div>
        <div className={classes.cancelDialogButtonsWrapper}>
          <Button onClick={onClose} priority={ButtonPriority.basicSecondary}>
            {t('uou-reservations.reservation-confirmation.modal-cancel-no')}
          </Button>
          <StatesButton
            type="button"
            onClick={cancelReservation}
            priority={ButtonPriority.primary}
            state={
              isCancelReservationLoading ? StatesButtonStates.IN_PROGRESS : StatesButtonStates.IDLE
            }
            idleContent={t('uou-reservations.reservation-confirmation.modal-cancel-yes')}
            inProgressContent={<ThreeDotsLoader className={classes.loader} />}
            data-hook={reservationConfirmationDataHooks.cancelReservationButton()}
            disabled={isCancelReservationLoading}
          />
        </div>
      </div>
    </Dialog>
  )
}
